import { UserRoles, type UserActions } from "~/types";
import { IdentificationIcon } from "@heroicons/vue/24/outline";
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import DocumentTextIcon from "@/assets/images/icons/document-text.svg";

export interface MenuItem {
  icon?: string;
  customIcon?:
    | FunctionalComponent<HTMLAttributes & VNodeProps, {}, any, {}>
    | string;
  to: string;
  title: string;
  actions?: UserActions[];
  roles?: UserRoles[];
}

export interface MenuGroup {
  title: string;
  items: MenuItem[];
  roles?: UserRoles[];
}

export const menuGroups: MenuGroup[] = [
  {
    title: "Administrativo",
    items: [
      // {
      //   customIcon: ChartBarIcon,
      //   title: "Dashboard",
      //   to: "/",
      //   roles: [UserRoles.OPERATIONAL],
      // },
      // {
      //   customIcon: HomeIcon,
      //   title: "Home",
      //   to: "/",
      //   roles: [UserRoles.SUPPLIER],
      // },
      {
        icon: "user-check",
        title: "Contratos",
        to: "/contratos",
        actions: Actions.GET_ALL_CONTRACTS,
      },
      {
        customIcon: IdentificationIcon,
        title: "Empresas",
        to: "/empresas",
        actions: Actions.GET_ALL_COMPANIES,
      },
      {
        customIcon: DocumentTextIcon,
        title: "Relatórios",
        to: "/relatorios",
        roles: [UserRoles.OPERATIONAL],
      },
    ],
  },
];
